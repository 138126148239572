import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { AboutHeader, BannerLeft, BannerButton } from "../utils"
import img from "../images/bcg/v-day-banner.jpg"

const ValentinesPage = () => (
  <Layout>
    <Seo
      title="Valentine's Day at azalea bar & kitchen"
      keywords={[`Valentine's Day in Asheville, NC near Biltmore`]}
    />
    <AboutHeader img={img}>
      <BannerLeft title="Valentine's Day Special" subtitle="Dine in or takeout">
        <hr />
        <p>
          This might be the loveliest deal in Asheville. For $65.99 you and your
          significant other will enjoy an appetizer, two entrees, and a shared
          dessert.
        </p>
        <BannerButton>
          <a href="/valentines-day-menu.pdf">View the Menu</a>
        </BannerButton>
      </BannerLeft>
    </AboutHeader>
  </Layout>
)

export default ValentinesPage
